/*border light color #f8f9fa */
.Button__button___vS7Mv {
  border-color: #343a40;
  background-color: transparent;
  border: 1px solid #343a40;
  color: #343a40;
  border-radius: 0.25rem;
}

.start-button {
  width: 35%;
}

.w_85 {
  width: 85%;
}
@media (max-height: 600px) {
  .Button__button___vS7Mv {
    border-color: #f8f9fa;
    background-color: #343a40;
    border: 1px solid #f8f9fa;
    color: #f8f9fa;
    border-radius: 0.25rem;
    margin-bottom: 25px;
  }
}

@media (max-width: 768px) {
  .start-button {
    width: 75%;
  }
  .Button__button___vS7Mv {
    border-color: #f8f9fa;
    background-color: #343a40;
    border: 1px solid #f8f9fa;
    color: #f8f9fa;
    border-radius: 0.25rem;
    margin-bottom: 25px;
  }
}

.page-wrapper {
  height: 50rem;
}
.library-wrapper {
  width: 70rem;
  height: 100%;
}

@media (max-width: 1200px) {
  .titleclass {
    width: 60rem;
  }
  .library-wrapper {
    width: 100%;
  }
  .page-wrapper {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .titleclass {
    width: 45rem;
  }
}

@media (max-width: 768px) {
  .titleclass {
    width: 30rem;
  }
}

@media (max-width: 500px) {
  .titleclass {
    width: 25rem;
  }
}
@media (max-width: 430px) {
  .titleclass {
    width: 20rem;
  }
}

.component-wrapper {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #c0c0c0fa;
  border-radius: 5px;
}
.progressBarParent {
  width: min-content;
}

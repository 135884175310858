.small-font {
  font-size: smaller;
}

.overflow-rules {
  overflow: auto;
  height: 50%;
}

.height-300 {
  height: 300px;
}

.smallest-font {
  font-size: small;
}

.newEntry-enter {
  opacity: 0;
  height: 0%;
}

.newEntry-enter.newEntry-enter-active {
  height: 400px;
  opacity: 1;
  transition: 300ms ease-in-out;
}

.newEntry-exit {
  opacity: 1;
  height: 400px;
}

.newEntry-exit.newEntry-exit-active {
  opacity: 0;
  height: 0%;
  transition: 400ms ease-out;
}

.slide-in-appear {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.slide-in-appear-active {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
}

.slide-in-exit {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
}

.slide-in-exit-active {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.slide-down-enter {
  opacity: 0;
  height: 0%;
}

.slide-down-enter.slide-down-enter-active {
  height: auto;
  opacity: 1;
}

.slide-down-exit {
  opacity: 1;
  height: auto;
}

.slide-down-exit.slide-down-exit-active {
  opacity: 0;
  height: 0%;
}
